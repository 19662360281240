<template>
      <div style="width: 628px; max-width: 100%; margin: 0 auto; padding-bottom: 150px; line-height:20px; background:#ffffff; padding:15px;">
           <div style="text-align: center; width: 100%; margin-bottom: 35px;">
                  <img style="width: 160px;" src="https://ristoe.oss-eu-central-1.aliyuncs.com/fWeA6SWZfr/98b76b3e7b649edd1408e7672035e712.png" alt="">
                  <div style="font-size: 1.3em;">Grazie per il tuo ordine</div>
            </div>
            <div>N. ordine: {{'2021/'+data.id}}</div>
            <div>Data: {{data.create_time}}</div>
            <div>Pagamento: {{data.payment == 'Carta' ? 'Pagato con la carta' : 'contanti alla consegna'}}</div>
            <div style="text-align: right;">
                  <div>{{data.type_order}}</div>
                  <div v-if="data.type_order == 'Consegna a domicilio'" >
                        {{data.address.street}}, {{data.address.number}}, {{data.address.zip_code}}, {{data.address.city}}
                  </div>
                  <div v-if="data.type_order == 'Ritira al ristorante'" >
                        {{data.shop.street}}, {{data.shop.number}}, {{data.shop.zip_code}}, {{data.shop.city}}
                  </div>
                  <div v-if="data.note" style="width:300px; text-align:left;">
                        Nota: {{data.note}}
                  </div>
            </div>
            <h1 style="margin:15px 0 5px; font-size:1.5em;">Dettaglio ordine</h1>
            <ul style="padding: 5px; border: 1px solid #0000002e; min-height: 300px; position: relative;">
                  <li v-for="(m, index) in data.menufixed" style="list-style: none; border-bottom: 1px solid #0000002e; padding-top: 10px;">
                        <div style="width: 60px; height: 40px; float: left; margin-right: 10px;">
                              <img :src="m.dishes.img" style="width: 100%; height: 100%; object-fit: cover;">
                        </div>
                        <div style="float:left;">
                              <div>{{m.dishes.code}}. {{m.dishes.name}}</div>
                              <ul>
                                    <li style="font-size:12px;" v-for="i in m.dishesSelected">{{i.id}}. {{i.name}}</li>
                              </ul>
                              <div>€{{m.dishes.price}} x1</div>  
                        </div>
                        <div style="width: 100%; text-align: right;">€{{parseFloat(m.dishes.price).toFixed(2)}}</div>
                        <div style="clear:both;"></div>
                  </li>
                  <hr>
                  <li v-for="(item, index) in data.dishes" :key="index" style="list-style: none; border-bottom: 1px solid #0000002e; padding-top: 10px;">
                        <div style="width: 60px; height: 40px; float: left; margin-right: 10px;">
                              <img :src="item.img" style="width: 100%; height: 100%; object-fit: cover;">
                        </div>
                        <div>
                              <div>{{item.code}}. {{item.name}}</div>
                              <div>€{{item.price}} x{{item.quantity}}</div>
                              <div style="width: 100%; text-align: right;">€{{parseFloat(item.price*item.quantity).toFixed(2)}}</div>
                        </div>
                  </li>


                  <li style="list-style: none; margin-top: 10px;  padding: 10px 0px 0 0;">
                        <div style="width: 100%; right: 5px; bottom: 10px;">    
                              <div style="width: 100%; text-align: right;"><span style="margin-right: 50px;">Subtotal:</span>{{data.subtotal}}</div>
                              <div style="width: 100%; text-align: right;"><span style="margin-right: 50px;">Sconto {{data.discount_percent}}%:</span>-€{{data.discount_price}}</div>
                              <div v-if="data.menu_price > 0" style="width: 100%; text-align: right;"><span style="margin-right: 50px;">{{data.menufixed.length}} x menu fisso:</span>€{{data.menu_price}}</div>
                              <div v-if="data.shipping_fee > 0" style="width: 100%; text-align: right;"><span style="margin-right: 50px;">Costo di consegna:</span>€{{data.shipping_fee}}</div>
                              <div style="width: 100%; text-align: right;"><span style="margin-right: 50px;">Totale:</span>€{{data.total_order}}</div>
                        </div>
                  </li>
            </ul>
            <div style="color:rgb(197 194 194)">Non valido come documento fiscale.</div>
            <div v-if="data.shop" style="text-align: center;">
                 <div>{{data.shop.shop_name}}</div>
                 <div>{{data.shop.company_name}}</div>
                 <div>{{data.shop.street + data.shop.number + ' ,' + data.shop.zip_code+ ' ,' + data.shop.city + ' ,' + data.shop.provence}}</div>
                 <div>Tel: {{data.shop.phone}}</div>
                 <div @click="goHome">Sito: <span style="border-bottom:1px solid #e5e5e5e5;">{{websiteHome}}</span></div>
                 <div>P.iva: {{data.shop.p_iva}}</div>
            </div>
            <div style="text-align:center; margin-top:10px;">
                  <van-button @click="goHome" style="width:300px; font-size:1.5em;" round type="primary">Home</van-button>
            </div>
      </div>
</template>
<script>
export default {
     name: 'success',
     data(){
           return{
                websiteHome: process.env.VUE_APP_BASE_URL,
                data:[]
           }
     },
     created(){
          this.$store.commit("emptyCart")
          this.axios.post('/webapi/Orders/getOrder', {order_number: this.$route.params.order}).then((res)=>{
                if(res.data.code != 200){
                      this.$router.push('/')
                }
                this.data = JSON.parse(res.data.data)
          }) 
     },
     methods:{
           goHome(){
                window.location.href = this.websiteHome
           }
     }
}
</script>